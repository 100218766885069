<template>
  <div class="guesses">
    <div class="tabs">
      <button 
        :class="{'active-tab': activeTab === 'myGuesses'}" 
        @click="activeTab = 'myGuesses'"
      >
        My Guesses
      </button>
      <button 
        :class="{'active-tab': activeTab === 'allGuesses'}" 
        @click="activeTab = 'allGuesses'"
      >
        All Guesses
      </button>
    </div>
    <div v-if="activeTab === 'myGuesses'" class="my-guesses">
      <h2 class="text-2xl font-bold text-neon-green mb-4">My Guesses</h2>
      <ul class="space-y-2">
        <li v-for="guess in myGuesses" :key="guess.id" class="bg-dark border border-neon-blue rounded-lg p-3 shadow-neon transition-all duration-300 hover:shadow-neon-intense">
          <span class="text-neon-blue">{{ guess.guess_text }}</span>
          <span class="float-right text-neon-pink">{{ guess.match_percentage }}%</span>
        </li>
      </ul>
    </div>
    <div v-if="activeTab === 'allGuesses'" class="all-guesses">
      <h2 class="text-2xl font-bold text-neon-yellow mb-4">All Guesses</h2>
      <ul class="space-y-2">
        <li v-for="guess in allGuesses" :key="guess.id" class="bg-dark border border-neon-purple rounded-lg p-3 shadow-neon transition-all duration-300 hover:shadow-neon-intense">
          <span class="text-neon-purple">{{ guess.username }}:</span>
          <span class="text-neon-blue ml-2">{{ guess.guess_text }}</span>
          <span class="float-right text-neon-green">{{ guess.match_percentage }}%</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { defineProps } from 'vue'

defineProps({
  myGuesses: {
    type: Array,
    required: true
  },
  allGuesses: {
    type: Array,
    required: true
  }
})

const activeTab = ref('myGuesses');
</script>

<style scoped>
.tabs {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

button {
  flex: 1;
  padding: 0.5rem 1rem;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
}

.active-tab {
  color: theme('colors.neon.blue');
  border-bottom: 2px solid theme('colors.neon.blue');
}

.shadow-neon {
  box-shadow: 0 0 5px theme('colors.neon.blue'), 0 0 20px theme('colors.neon.blue');
}

.shadow-neon-intense {
  box-shadow: 0 0 10px theme('colors.neon.blue'), 0 0 30px theme('colors.neon.blue');
}

li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-neon-blue {
  flex-grow: 1;
  margin-right: 1rem;
}

.text-neon-purple {
  margin-right: 0.5rem;
}
</style>
