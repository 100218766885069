// src/api.js
import axios from 'axios'
import { retrieveLaunchParams } from '@telegram-apps/sdk';

const api = axios.create({
  baseURL: '/api',
})

const getAuthHeader = () => {
  let initDataRaw = null;
  try {
    if (process.env.NODE_ENV === 'development') {
      initDataRaw = "test";
    } else {
      initDataRaw = retrieveLaunchParams().initDataRaw;
    }
  } catch (error) {
    console.error('Error retrieving launch params:', error);
    if (process.env.NODE_ENV === 'development') {
      initDataRaw = "test";
    }
  }
  return { Authorization: `tma ${initDataRaw}` };
}

export default {
  submitGuess(data) {
    return api.post('/submit_guess', data, { headers: getAuthHeader() })
  },
  getGuesses(roundId) {
    return api.get(`/get_guesses/${roundId}`, { headers: getAuthHeader() })
  },
  getCurrentRound() {
    return api.get('/current_round', { headers: getAuthHeader() })
  },
  getLeaderboard(timeframe = 'all') {
    return api.get(`/leaderboard?timeframe=${timeframe}`, { headers: getAuthHeader() })
  },
  getUserInfo() {
    return api.get('/user_info', { headers: getAuthHeader() })
  },
}
