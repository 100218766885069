<script setup>
import { RouterLink, RouterView } from 'vue-router'
import { viewport } from '@telegram-apps/sdk';

try {
  viewport.expand();
} catch (error) {
  console.log('Failed to expand viewport:', error);
}
</script>

<template>
  <div id="app" class="min-h-screen bg-dark text-neon-blue font-sans antialiased">
    <header class="py-6 border-b border-neon-blue shadow-neon">
      <div class="container mx-auto px-4">
        <h2 class="text-4xl font-bold text-neon-pink text-center">IMGuess</h2>
      </div>
    </header>

    <main class="container mx-auto mt-8 px-4 pb-20">
      <!-- Added pb-20 to prevent content from being hidden behind the navigation -->
      <RouterView />
    </main>

    <!-- Fixed bottom navigation -->
    <nav class="fixed bottom-0 left-0 right-0 bg-dark border-t border-neon-blue shadow-neon-up">
      <div class="grid grid-cols-4 h-16">
        <RouterLink to="/" class="flex flex-col items-center justify-center hover:text-neon-pink" :class="{ 'text-neon-pink': $route.path === '/' }">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
          </svg>
          <span class="text-xs mt-1">Game</span>
        </RouterLink>

        <RouterLink to="/leaderboard" class="flex flex-col items-center justify-center hover:text-neon-pink" :class="{ 'text-neon-pink': $route.path === '/leaderboard' }">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
          </svg>
          <span class="text-xs mt-1">Leaderboard</span>
        </RouterLink>

        <RouterLink to="/rules" class="flex flex-col items-center justify-center hover:text-neon-pink" :class="{ 'text-neon-pink': $route.path === '/rules' }">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <span class="text-xs mt-1">Rules</span>
        </RouterLink>

        <RouterLink to="/profile" class="flex flex-col items-center justify-center hover:text-neon-pink" :class="{ 'text-neon-pink': $route.path === '/profile' }">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
          </svg>
          <span class="text-xs mt-1">Profile</span>
        </RouterLink>
      </div>
    </nav>

    <footer class="mt-16 py-4 text-neon-green text-sm">
      <p class="text-center">&copy; 2024 IMGuess. All rights reserved.</p>
    </footer>
  </div>
</template>

<style>
body {
  background-color: #1A1A1A;
}

.shadow-neon {
  box-shadow: 0 0 5px theme('colors.neon.blue'), 0 0 20px theme('colors.neon.blue');
}

.shadow-neon-up {
  box-shadow: 0 -2px 5px theme('colors.neon.blue'), 0 -2px 20px theme('colors.neon.blue');
}
</style>
