<template>
  <div class="leaderboard">
    <h2 class="text-2xl font-bold mb-4">Leaderboard</h2>
    
    <!-- Timeframe selector -->
    <div class="flex gap-2 mb-4">
      <button 
        v-for="timeframe in timeframes" 
        :key="timeframe.value"
        @click="selectedTimeframe = timeframe.value"
        :class="[
          'px-3 py-1 rounded',
          selectedTimeframe === timeframe.value 
            ? 'bg-neon-blue text-black' 
            : 'bg-gray-800 text-neon-blue'
        ]"
      >
        {{ timeframe.label }}
      </button>
    </div>

    <div v-if="leaderboard.length" class="space-y-2">
      <div v-for="(user, index) in leaderboard" :key="index" class="flex justify-between items-center bg-gray-800 p-2 rounded">
        <span class="text-neon-blue">{{ index + 1 }}. {{ user.username }}</span>
        <span class="font-bold text-neon-pink">{{ user.score }}</span>
      </div>
    </div>
    <div v-else class="text-center text-gray-500">
      No data available
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import api from '../api';

export default {
  name: 'LeaderboardView',
  setup() {
    const leaderboard = ref([]);
    const selectedTimeframe = ref('all');
    const timeframes = [
      { label: 'All Time', value: 'all' },
      { label: 'Weekly', value: 'weekly' },
      { label: 'Monthly', value: 'monthly' }
    ];

    const fetchLeaderboard = async (timeframe) => {
      try {
        const response = await api.getLeaderboard(timeframe);
        leaderboard.value = response.data.leaderboard;
      } catch (error) {
        console.error('Failed to fetch leaderboard:', error);
      }
    };

    onMounted(() => {
      fetchLeaderboard(selectedTimeframe.value);
    });

    watch(selectedTimeframe, (newTimeframe) => {
      fetchLeaderboard(newTimeframe);
    });

    return {
      leaderboard,
      selectedTimeframe,
      timeframes
    };
  }
};
</script>
