<template>
  <div class="container mx-auto p-4">
    <div v-if="loading" class="loading-container">
      <div class="cyber-spinner"></div>
      <span class="text-neon-blue">LOADING USER DATA...</span>
    </div>
    
    <div v-else-if="error" class="error-container">
      <div class="error-icon">⚠️</div>
      <div class="text-neon-pink text-center">{{ error }}</div>
    </div>
    
    <div v-else class="user-profile">
      <div class="profile-header">
        <div class="neon-border"></div>
        <h1 class="text-3xl font-bold text-neon-green tracking-wider">AGENT PROFILE</h1>
        <div class="neon-border"></div>
      </div>

      <div class="stats-grid">
        <!-- Username Card -->
        <div class="stat-card">
          <div class="stat-icon">👤</div>
          <div class="stat-label">AGENT ID</div>
          <div class="stat-value text-neon-blue">{{ userInfo.username }}</div>
        </div>

        <!-- Score Card -->
        <div class="stat-card">
          <div class="stat-icon">🏆</div>
          <div class="stat-label">REPUTATION SCORE</div>
          <div class="stat-value text-neon-pink">{{ userInfo.score }}</div>
        </div>

        <!-- Round Attempts Card -->
        <div class="stat-card">
          <div class="stat-icon">🎯</div>
          <div class="stat-label">ROUND ATTEMPTS</div>
          <div class="stat-value text-neon-yellow">{{ userInfo.round_attempts }}</div>
        </div>

        <!-- General Attempts Card -->
        <div class="stat-card">
          <div class="stat-icon">🔄</div>
          <div class="stat-label">GENERAL ATTEMPTS</div>
          <div class="stat-value text-neon-purple">{{ userInfo.attempts }}</div>
        </div>
      </div>

      <!-- Referral Section -->
      <div class="referral-section">
        <h2 class="text-xl font-bold text-neon-blue mb-4">RECRUITMENT PROTOCOL</h2>
        <div class="referral-card">
          <div class="flex items-center justify-between relative">
            <span class="text-neon-green">REFERRAL CODE:</span>
            <div class="flex items-center gap-2">
              <button 
                @click="copyReferralLink" 
                class="copy-button"
              >
                COPY
              </button>
            </div>
          </div>
          <transition name="fade">
            <span v-if="showCopyConfirmation" 
                  class="copy-confirmation">
              COPIED TO CLIPBOARD ✓
            </span>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'

export default {
  data() {
    return {
      userInfo: null,
      loading: true,
      error: null,
      showCopyConfirmation: false
    }
  },
  mounted() {
    this.fetchUserInfo()
  },
  methods: {
    async fetchUserInfo() {
      try {
        this.loading = true
        const response = await api.getUserInfo()
        this.userInfo = response.data
        this.loading = false
      } catch (err) {
        this.error = 'SYSTEM ERROR: FAILED TO LOAD USER DATA'
        this.loading = false
        console.error('Error fetching user info:', err)
      }
    },
    async copyReferralLink() {
      if (this.userInfo && this.userInfo.referral_link) {
        try {
          await navigator.clipboard.writeText(this.userInfo.referral_link)
          this.showCopyConfirmation = true
          setTimeout(() => {
            this.showCopyConfirmation = false
          }, 2000)
        } catch (err) {
          console.error('Failed to copy referral link:', err)
          this.error = 'ERROR: CLIPBOARD ACCESS DENIED'
        }
      }
    }
  }
}
</script>

<style scoped>
.user-profile {
  background: rgba(0, 0, 0, 0.7);
  border-radius: 1rem;
  padding: 2rem;
  position: relative;
}

.profile-header {
  text-align: center;
  margin-bottom: 3rem;
  position: relative;
}

.neon-border {
  height: 2px;
  background: linear-gradient(90deg, 
    transparent, 
    theme('colors.neon.blue'), 
    theme('colors.neon.pink'), 
    transparent
  );
  margin: 1rem 0;
  animation: borderFlow 2s linear infinite;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.stat-card {
  background: rgba(20, 20, 30, 0.8);
  padding: 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid theme('colors.neon.blue');
  transition: all 0.3s ease;
  text-align: center;
}

.stat-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 15px theme('colors.neon.blue');
}

.stat-icon {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.stat-label {
  color: theme('colors.neon.blue');
  font-size: 0.8rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.stat-value {
  font-size: 1.5rem;
  font-weight: bold;
}

.referral-section {
  margin-top: 2rem;
}

.referral-card {
  background: rgba(20, 20, 30, 0.8);
  padding: 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid theme('colors.neon.green');
  position: relative;
}

.copy-button {
  background: theme('colors.neon.green');
  color: black;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  font-weight: bold;
  transition: all 0.3s ease;
}

.copy-button:hover {
  background: theme('colors.neon.blue');
  box-shadow: 0 0 10px theme('colors.neon.blue');
}

.copy-confirmation {
  color: theme('colors.neon.green');
  font-size: 0.8rem;
  position: absolute;
  left: 0;
  bottom: -1.5rem;
  width: 100%;
  text-align: center;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 2rem;
}

.cyber-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid transparent;
  border-top-color: theme('colors.neon.blue');
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.error-container {
  text-align: center;
  padding: 2rem;
}

.error-icon {
  font-size: 3rem;
  margin-bottom: 1rem;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

@keyframes borderFlow {
  0% { transform: scaleX(0.8); opacity: 0.5; }
  50% { transform: scaleX(1.2); opacity: 1; }
  100% { transform: scaleX(0.8); opacity: 0.5; }
}

@media (max-width: 640px) {
  .stats-grid {
    grid-template-columns: 1fr;
  }
}
</style>
