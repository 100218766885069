<template>
  <div class="rules-container">
    <div class="rules-header">
      <div class="neon-border"></div>
      <h2 class="text-3xl font-bold mb-6 text-neon-green tracking-wider">SYSTEM PROTOCOLS</h2>
    </div>

    <div class="rules-grid">
      <!-- Objective Card -->
      <div class="rule-card">
        <div class="rule-icon">🎯</div>
        <h3 class="text-neon-pink font-bold mb-2">PRIME DIRECTIVE</h3>
        <p>Match descriptions with maximum precision. Target accuracy: 98%+</p>
      </div>

      <!-- Gameplay Card -->
      <div class="rule-card">
        <div class="rule-icon">🎮</div>
        <h3 class="text-neon-blue font-bold mb-2">OPERATION PROTOCOL</h3>
        <p>Submit guesses during active rounds. Each attempt generates a match percentage.</p>
      </div>

      <!-- Rounds Card -->
      <div class="rule-card">
        <div class="rule-icon">⏱️</div>
        <h3 class="text-neon-yellow font-bold mb-2">TEMPORAL STRUCTURE</h3>
        <p>Multiple rounds with defined time windows. Manual override possible.</p>
      </div>

      <!-- Points Card -->
      <div class="rule-card">
        <div class="rule-icon">💎</div>
        <h3 class="text-neon-purple font-bold mb-2">REWARD MATRIX</h3>
        <div class="point-distribution">
          <div class="point-row">
            <span class="text-neon-blue">TOP MATCH</span>
            <span class="text-neon-pink">50 PTS</span>
          </div>
          <div class="point-row">
            <span class="text-neon-blue">RUNNER-UP</span>
            <span class="text-neon-pink">25 PTS</span>
          </div>
          <div class="point-row">
            <span class="text-neon-blue">THIRD PLACE</span>
            <span class="text-neon-pink">15 PTS</span>
          </div>
          <div class="point-row">
            <span class="text-neon-blue">FOURTH PLACE</span>
            <span class="text-neon-pink">7 PTS</span>
          </div>
          <div class="point-row">
            <span class="text-neon-blue">FIFTH PLACE</span>
            <span class="text-neon-pink">3 PTS</span>
          </div>
        </div>
      </div>

      <!-- Hints Card -->
      <div class="rule-card">
        <div class="rule-icon">💡</div>
        <h3 class="text-neon-green font-bold mb-2">ASSISTANCE PROTOCOL</h3>
        <p>Access partial text reveals through the hint system. Strategic usage recommended.</p>
      </div>

      <!-- Limits Card -->
      <div class="rule-card">
        <div class="rule-icon">⚠️</div>
        <h3 class="text-neon-orange font-bold mb-2">SYSTEM LIMITATIONS</h3>
        <p>Attempts per round are restricted. Monitor your remaining tries carefully.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RulesView'
};
</script>

<style scoped>
.rules-container {
  padding: 2rem;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 1rem;
  position: relative;
  overflow: hidden;
}

.rules-header {
  position: relative;
  text-align: center;
  margin-bottom: 2rem;
}

.neon-border {
  position: absolute;
  top: -2px;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  height: 2px;
  background: linear-gradient(90deg, 
    transparent, 
    theme('colors.neon.blue'), 
    theme('colors.neon.pink'), 
    transparent
  );
  animation: borderFlow 2s linear infinite;
}

.rules-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1.5rem;
}

.rule-card {
  background: rgba(20, 20, 30, 0.8);
  padding: 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid theme('colors.neon.blue');
  transition: all 0.3s ease;
}

.rule-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 15px theme('colors.neon.blue');
}

.rule-icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.point-distribution {
  margin-top: 1rem;
}

.point-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}

@keyframes borderFlow {
  0% { transform: translateX(-150%) scaleX(1.5); }
  100% { transform: translateX(150%) scaleX(1.5); }
}

@media (max-width: 640px) {
  .rules-grid {
    grid-template-columns: 1fr;
  }
}
</style>
