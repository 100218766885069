<template>
  <Transition name="fade">
    <div v-if="show" class="fixed inset-0 flex items-center justify-center z-50">
      <div class="bg-dark/90 p-6 rounded-lg border-2" 
           :class="modalClass">
        <div class="text-center">
          <h3 class="text-2xl font-bold mb-2" :class="textClass">
            {{ modalTitle }}
          </h3>
          <p v-if="!result.noAttemptsLeft" class="text-xl">
            {{ result.percentage }}% match
          </p>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup>
import { computed } from 'vue'
import { defineProps } from 'vue'

const props = defineProps({
  show: Boolean,
  result: Object
})

const modalClass = computed(() => {
  if (props.result.noAttemptsLeft) return 'border-neon-pink'
  return props.result.won ? 'border-neon-green' : 'border-neon-blue'
})

const textClass = computed(() => {
  if (props.result.noAttemptsLeft) return 'text-neon-pink'
  return props.result.won ? 'text-neon-green' : 'text-neon-blue'
})

const modalTitle = computed(() => {
  if (props.result.noAttemptsLeft) return 'No attempts left!'
  return props.result.won ? 'Correct!' : 'Try again!'
})
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
