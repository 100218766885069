<template>
  <div class="home-page flex flex-col min-h-[100dvh]">
    <div class="flex-none">
      <h2 class="text-3xl font-bold text-neon-green">Current Round {{ round.id }}</h2>
      
      <div class="text-neon-blue text-xl mb-4 font-mono">
        {{ round.revealed_description }}
      </div>
      
      <div v-if="timeRemaining > 0" class="text-neon-pink mb-4">
        Time remaining: {{ formatTimeRemaining }}
      </div>
    </div>

    <div class="flex-1 overflow-auto">
      <div class="relative mb-4">
        <img :src="round.image_url" alt="AI Generated Image" class="w-full max-w-2xl mx-auto rounded-lg shadow-neon" />
        <div class="absolute inset-0 border-2 border-neon-pink rounded-lg pointer-events-none"></div>
      </div>
      
      <div class="sticky bottom-0 bg-dark py-4">
        <div class="flex justify-center gap-6 mb-3 text-neon-blue">
          <div class="text-center">
            <span class="font-bold">Round Attempts:</span>
            <span class="ml-2">{{ round.round_attempts || 0 }}</span>
          </div>
          <div class="text-center">
            <span class="font-bold">General Attempts:</span>
            <span class="ml-2">{{ round.attempts || 0 }}</span>
          </div>
        </div>
        <GuessForm :round-id="round.id" @guess-submitted="handleGuessSubmitted" />
      </div>
    </div>

    <div class="flex-none mt-4">
      <GuessList :my-guesses="myGuesses" :all-guesses="allGuesses" />
    </div>

    <GuessResultModal :show="showModal" :result="guessResult" />
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed } from 'vue'
import api from '../api'
import GuessForm from './GuessForm.vue'
import GuessList from './GuessList.vue'
import GuessResultModal from './GuessResultModal.vue'

const round = ref({})
const myGuesses = ref([])
const allGuesses = ref([])
const showModal = ref(false)
const guessResult = ref({ won: false, percentage: 0 })

const timeRemaining = computed(() => {
  if (!round.value.end_time) return 0
  const endTime = new Date(round.value.end_time + 'Z') // Add 'Z' to parse as UTC
  const now = new Date()
  return Math.max(0, endTime - now)
})

const formatTimeRemaining = computed(() => {
  const hours = Math.floor(timeRemaining.value / (1000 * 60 * 60))
  const minutes = Math.floor((timeRemaining.value % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((timeRemaining.value % (1000 * 60)) / 1000)
  
  const formatNumber = (num) => num.toString().padStart(2, '0')
  return `${formatNumber(hours)}h ${formatNumber(minutes)}m ${formatNumber(seconds)}s`
})

const fetchCurrentRound = async () => {
  const response = await api.getCurrentRound()
  round.value = response.data
}

const fetchGuesses = async () => {
  const response = await api.getGuesses(round.value.id)
  myGuesses.value = response.data.my_guesses
  allGuesses.value = response.data.all_guesses
}

const handleGuessSubmitted = (result) => {
  guessResult.value = {
    won: result.won || false,
    percentage: result.percentage || 0,
    noAttemptsLeft: result.noAttemptsLeft || false
  }
  round.value.round_attempts = result.round_attempts
  round.value.attempts = result.attempts
  showModal.value = true
  fetchGuesses()

  setTimeout(() => {
    showModal.value = false
  }, 2000)
}

const pollingInterval = ref(null)

onMounted(async () => {
  await fetchCurrentRound()
  await fetchGuesses()
  
  pollingInterval.value = setInterval(async () => {
    await fetchCurrentRound()
    await fetchGuesses()
  }, 5000)
})

onUnmounted(() => {
  if (pollingInterval.value) {
    clearInterval(pollingInterval.value)
  }
})
</script>

<style scoped>
.shadow-neon {
  box-shadow: 0 0 10px theme('colors.neon.blue'), 0 0 30px theme('colors.neon.blue');
}
</style>
