<template>
  <div class="guess-form sticky bottom-0">
    <form @submit.prevent="submitGuess" class="flex flex-col sm:flex-row items-center justify-center gap-4">
      <input 
        v-model="guess" 
        type="text" 
        placeholder="Enter your guess" 
        @focus="scrollToButton"
        class="w-full sm:w-64 px-4 py-2 bg-dark text-neon-blue border-2 border-neon-blue rounded-md focus:outline-none focus:ring-2 focus:ring-neon-pink focus:border-transparent transition-all duration-300 placeholder-neon-blue/50"
      >
      <button 
        type="submit" 
        class="w-full sm:w-auto px-6 py-2 bg-neon-pink text-dark font-bold rounded-md hover:bg-neon-blue hover:text-dark transition-all duration-300 shadow-neon"
        ref="submitButton"
      >
        Submit
      </button>
    </form>
    <p v-if="errorMessage" class="text-neon-pink mt-2">{{ errorMessage }}</p>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import api from '../api'
import { defineProps, defineEmits } from 'vue'

const props = defineProps({
  roundId: {
    type: Number,
    required: true
  }
})

const emit = defineEmits(['guess-submitted'])
const guess = ref('')
const errorMessage = ref('')

const submitButton = ref(null)

const submitGuess = async () => {
  if (!guess.value.trim()) return; 
  errorMessage.value = '';

  try {
    const data = {
      round_id: props.roundId,
      guess_text: guess.value
    }
    
    const response = await api.submitGuess(data)
    
    emit('guess-submitted', {
      won: response.data.won,
      percentage: response.data.match_percentage,
      round_attempts: response.data.round_attempts,
      attempts: response.data.attempts
    })
    
    guess.value = ''
  } catch (error) {
    console.error('Error submitting guess:', error)
    if (error.response && error.response.status === 400 && error.response.data.error === 'No attempts left') {
      emit('guess-submitted', {
        noAttemptsLeft: true,
        round_attempts: 0,
        attempts: 0
      })
    } else if (error.response && error.response.status === 500) {
      errorMessage.value = 'This guess already exists. Please try a different one.'
    } else {
      errorMessage.value = 'An error occurred while submitting your guess. Please try again.'
    }
  }
}

const scrollToButton = () => {
  setTimeout(() => {
    submitButton.value.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, 100);
}
</script>

<style scoped>
.shadow-neon {
  box-shadow: 0 0 5px theme('colors.neon.pink'), 0 0 20px theme('colors.neon.pink');
}

input::placeholder {
  color: theme('colors.neon.blue');
  opacity: 0.5;
}

.guess-form {
  position: sticky;
  bottom: 0;
  background-color: var(--color-dark);
  padding: 1rem;
  z-index: 10;
}
</style>
