// src/router.js
import { createRouter, createWebHistory } from 'vue-router'
import Home from './components/HomePage.vue'
import LeaderboardView from './components/Leaderboard.vue'
import RulesView from './components/Rules.vue'
import UserPage from './components/UserPage.vue'

const routes = [
  { path: '/', component: Home },
  { path: '/leaderboard', component: LeaderboardView },
  { path: '/rules', component: RulesView },
  { path: '/profile', component: UserPage },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
